import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap'
import moment from 'moment';
import './style.css'

export default function UserTable(props) {

    return (
        <>
            <Container fluid >
                <Row style={{ padding: 0 }}>
                    <h4 className='tableSportsTitle'>{props.title}</h4>
                    <Row className='gameTableContainer' >
                        <Table responsive >

                            {/* <thead>
                                <tr className="tableHead" >
                                    <th>Date</th>
                                    <th>Home</th>
                                    <th>Away</th>
                                </tr>
                            </thead> */}
                            <tbody>
                                {
                                    props.data?.map((data, index) => (
                                        <tr key={index} className='tableRow'>
                                            <td className="tableTimeCell">
                                                Date:
                                                <span style={{ fontWeight: 400 }}>{" " + moment().format('L')}</span>
                                            </td>
                                            <td className="tableCell">
                                                <p>Home: <span style={{ fontWeight: 400 }}>{" " + data?.home_team}</span></p>
                                            </td>
                                            <td className="tableCell">
                                                <p>Away: <span style={{ fontWeight: 400 }}>{" " + data?.away_team}</span></p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Row>
            </Container>
        </>
    )
}
