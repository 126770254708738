// import React from 'react';
import { BsFileEarmarkSpreadsheet, BsGlobeEuropeAfrica } from 'react-icons/bs'
import { BiMoneyWithdraw, BiCircleThreeQuarter, BiAnalyse, BiLineChart, BiBroadcast, BiBrain, BiTimer } from 'react-icons/bi'
import { TbBrandDaysCounter } from 'react-icons/tb'
import { HiCubeTransparent } from 'react-icons/hi'
import { GiProgression } from 'react-icons/gi'

export const types = [
    {
        id: 1,
        title: "Point Spreads",
        icon: <BsFileEarmarkSpreadsheet color="#fff" size={20} />,
    },
    {
        id: 2,
        title: "Money Lines",
        icon: <BiMoneyWithdraw color="#fff" size={20} />,
    },
    {
        id: 3,
        title: "Totals",
        icon: <TbBrandDaysCounter color="#fff" size={20} />,
    }
    ,
    {
        id: 4,
        title: "European Prices",
        icon: <BsGlobeEuropeAfrica color="#fff" size={20} />,
    },
    {
        id: 5,
        title: "Quarter Lines",
        icon: <BiCircleThreeQuarter color="#fff" size={20} />,
    },
    {
        id: 6,
        title: "Proposotions",
        icon: <BiAnalyse color="#fff" size={20} />,
    },
    {
        id: 7,
        title: "Parlays",
        icon: <BiLineChart color="#fff" size={20} />,
    },
    {
        id: 8,
        title: "Futures",
        icon: <BiBrain color="#fff" size={20} />,
    },
    {
        id: 9,
        title: "Teasers",
        icon: <BiBroadcast color="#fff" size={20} />,
    },
    {
        id: 10,
        title: "If Bets",
        icon: <HiCubeTransparent color="#fff" size={20} />,
    },
    {
        id: 11,
        title: "Half-time Lines",
        icon: <BiTimer color="#fff" size={20} />,
    },
    {
        id: 12,
        title: "Progressive Parlays",
        icon: <GiProgression color="#fff" size={20} />,
    }

]

