import React, { useState } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { contactUs } from '../../api';
import './styles.css'

export default function ContactModal({ Close, setSupportModalShow }) {

    const [errorMessage, setErrorMessage] = useState()

    const formik = useFormik({
        initialValues: {
            email: '',
            message: '',
        },
        onSubmit: () => {
            const email = formik.values.email
            const message = formik.values.message;
            const func = contactUs(email, message)
            func.then(async (response) => {
                if (response.success) {
                    window.location.reload()
                    Close(true)
                    setSupportModalShow(false)
                }
            })
        }
    })



    return (
        <div className='contactUsWrapper'>
            <Container>
                <p style={{ color: 'red' }}>
                    {errorMessage}
                </p>
                {/* <p className='contactUsText'>Contact us for any questions or feedback, we're here to help!</p> */}
                <Form onSubmit={formik.handleSubmit}>
                    <p className='contactUsText'>Send To: <span>Support@bookiepoint.com</span></p>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            className='textInput'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            type="email"
                            placeholder="From"
                            name="email" />
                        <Form.Control
                            className='textInput'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            as="textarea" rows={5}
                            placeholder="Message"
                            name="message"
                            style={{ marginTop: 8 }} />
                    </Form.Group>
                    <Button type="submit" className='contactButton'>
                        Contact Us
                    </Button>
                </Form>
            </Container>
        </div>
    )
}
