import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './style.css';
import image1 from '../../assets/images/image1.jpg'
import image2 from '../../assets/images/image2.jpg'
import image3 from '../../assets/images/image3.jpeg'
import image4 from '../../assets/images/image4.jpeg'
import image5 from '../../assets/images/image5.jpeg'
import image6 from '../../assets/images/image6.webp'

export default function Slideshow() {
    return (
        <div className='slideShow'>
            <Swiper
                loop={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                className="mySwiper"
            >
                <SwiperSlide className='swiper'>
                    <img src={image1} alt='image1' />
                </SwiperSlide>
                <SwiperSlide className='swiper'>
                    <img src={image2} alt='image2' />
                </SwiperSlide>
                <SwiperSlide className='swiper'>
                    <img src={image3} alt='image3' />
                </SwiperSlide>
                <SwiperSlide className='swiper'>
                    <img src={image4} alt='image4' />
                </SwiperSlide>
                <SwiperSlide className='swiper'>
                    <img src={image5} alt='image5' />
                </SwiperSlide>
                <SwiperSlide className='swiper'>
                    <img src={image6} alt='image6' />
                </SwiperSlide>
            </Swiper>
        </div>
    );
}
