import React from 'react'
import './styles.css'
import { Row } from 'react-bootstrap'
import { reviews } from './data'

const Reviews = () => {
    return (
        <div className='reviewsWrapper'>
            <h4 className='reviewsTitle'>Reviews</h4>
            <div className='ratingContainer'>
                <h1 className='rating'>5.0</h1>
                <span class="fa fa-star fa-2x checked"></span>
                <span class="fa fa-star fa-2x checked"></span>
                <span class="fa fa-star fa-2x checked"></span>
                <span class="fa fa-star fa-2x checked"></span>
                <span class="fa fa-star fa-2x checked"></span>
                <span className='reviews'>15 reviews</span>
            </div>

            <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className='reviewsContainer'>
                    {
                        reviews.map(review => (
                            <div className='reviewContainer'>
                                <div className='reviewAvatarName'>
                                    <div className='reviewAvatar'>{review.name.charAt(0).toUpperCase()}</div>
                                    <div>
                                        <h5 className='reviewName'>{review.name}</h5>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                        <span class="fa fa-star checked"></span>
                                    </div>
                                </div>
                                <div className='reviewComment'>
                                    <h5>{review.comment}</h5>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Row>
        </div>
    )
}

export default Reviews