import { Button, Col, Container, Row } from 'react-bootstrap';
import './style.css'
import moment from 'moment';


export default function News({ news }) {

    return (
        <>
            <Container style={{ padding: 0, marginTop: 32 }}>
                <h4 className='newsTitle mb-4'>News</h4>
                <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    {
                        news?.map((data, index) => (
                            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                                <div className='newsContainer'>
                                    <div className='newsImage'>
                                        <img src={data.enclosures[0].url} alt="news" width={200} height={150} />
                                    </div>
                                    <div className='newsBody'>
                                        <p className="newsBodyTitle">{data.title}</p>
                                        <div className='newsBodyDateButton'>
                                            <p className='newsDate' >{moment(data.published).format('L HH:MM')}</p>
                                            <Button className='newsReadMoreButton'>
                                                <a
                                                    href={data.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Read More
                                                </a>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </>
    )
}
