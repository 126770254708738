export const reviews = [
    {
        name: "John Doe",
        rating: 4.5,
        "comment": "I've been using this sports betting website for a while now, and it's been a great experience. The user interface is intuitive, and I appreciate the wide range of sports and markets they offer. Highly recommended! The customer support is also top-notch and has been very helpful whenever I've had questions or issues."
    },
    {
        name: "Alice Smith",
        rating: 5.0,
        "comment": "I'm new to sports betting, and this site has been a fantastic introduction. They provide helpful guides and tips, and the live betting feature is super exciting. I've already made some good wins! The mobile app is a game-changer; it allows me to place bets on the go, and the user experience is superb. I can't wait to explore more betting options on this platform."
    },
    {
        name: "David Johnson",
        rating: 4.0,
        "comment": "One of the things I love about this sports betting site is the quick and responsive customer support. They're always there to assist with any issues or questions I have. It adds a lot of value to the platform. The odds are competitive, and the website is easy to navigate. The only improvement I'd suggest is to add more variety to their promotions."
    },
    {
        name: "Emily Wilson",
        rating: 4.2,
        "comment": "The odds on this site are competitive, and the payouts have been smooth and hassle-free. I appreciate the reliability, and it's become my go-to place for sports betting. The live streaming feature is a bonus; it enhances the overall experience, especially for in-play betting. Keep up the good work!"
    },
    {
        name: "Michael Brown",
        rating: 5.0,
        "comment": "The mobile app is fantastic! It's easy to navigate, and I can place bets on the go. Plus, they offer live streaming for many events, making it even more exciting. The site has a wide range of sports and markets, which keeps things interesting. I've had a great time here and look forward to more wins!"
    },
    {
        name: "Sarah Davis",
        rating: 3.8,
        "comment": "I've had a great experience with this sports betting website. The variety of sports they cover is impressive, and their in-play betting feature keeps things thrilling. However, I've had a couple of issues with the mobile app's performance. It could use some optimization."
    },
    {
        name: "James White",
        rating: 4.0,
        "comment": "Decent sports betting site, but I wish they had more promotions and bonuses for loyal customers. The odds are good, though. The customer support team is helpful when you manage to reach them. More promotions would make this site even better."
    },
    {
        name: "Laura Miller",
        rating: 4.7,
        "comment": "I love the cash-out feature! It's saved me from some potential losses. The site is user-friendly, and I appreciate the fast withdrawals. The odds have been favorable, and I've been consistently winning. Highly recommended for sports bettors."
    },
    {
        name: "Robert Taylor",
        rating: 3.5,
        "comment": "The customer service is hit or miss. Sometimes it's excellent, other times not so much. Betting options are solid, though. The site layout could use some improvement for better usability."
    },
    {
        name: "Elizabeth Brown",
        rating: 4.6,
        "comment": "I've had a profitable run here. The platform is reliable, and I've never had any issues with payments or withdrawals. The variety of sports and markets keeps me engaged, and the in-play betting feature is a real adrenaline rush."
    },
    {
        name: "Daniel Clark",
        rating: 4.2,
        "comment": "A good sports betting site with a wide range of markets. The live chat support is responsive and helpful when needed. However, I've encountered some occasional lag when using the live streaming feature. It could use some optimization."
    },
    {
        name: "Jennifer Turner",
        rating: 3.0,
        "comment": "Average sports betting site. The interface could use some improvement, but it gets the job done. The odds are decent, and payouts are on time. I'm looking forward to seeing more improvements in the future."
    },
    {
        name: "William Harris",
        rating: 4.8,
        "comment": "This site has some of the best odds I've seen. I've been consistently winning, and payouts are fast. The variety of betting options and sports coverage is impressive. The user interface is clean and easy to navigate."
    },
    {
        name: "Olivia Anderson",
        rating: 4.6,
        "comment": "I appreciate the educational resources they provide for beginners. It helped me get started with sports betting. The customer support team is friendly and knowledgeable. Overall, a great platform for both beginners and experienced bettors."
    },
    {
        name: "Matthew Lee",
        rating: 4.0,
        "comment": "Solid sports betting platform. The app works well, and I've never experienced downtime. The odds are competitive, and I've had a positive betting experience overall. Keep it up!"
    }
]
