import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Cookies from 'universal-cookie';
import Modale from '../modal';
import { navLinks } from '../navigation/navLinks';
import { types } from '../types/types';
import { leagues } from '../usaLeagues/data';
import './style.css';

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <Container className='footerWrapper'>
            <Container>
                <Row className="linksContainer" sm={3} md={3} xs={2} >
                    <Col lg={2} md={3} sm={12} className="socialMediaWrapper" >
                        <Row className='socialMediaContainer'>
                            <div className="logo"></div>
                        </Row>
                    </Col>
                    <Col lg={6} md={2} sm={12}></Col>
                    <Col lg={4} md={7} sm={12}>
                        <p className='footerText'>
                            18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au
                        </p>
                    </Col>
                </Row>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className='footerSportsLinksContainer'>
                            <h4 className='mb-4'><strong>Sports</strong></h4>
                            {
                                navLinks?.map(navLink => (
                                    <p onClick={() => setModalShow(true)}>
                                        {navLink.link}
                                    </p>
                                ))
                            }
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className='footerTypesLinksContainer'>
                            <h4 className='mb-4'><strong>Types</strong></h4>
                            {
                                types?.slice(0, 8).map((type) => (
                                    <p onClick={() => setModalShow(true)}>
                                        {type.title}
                                    </p>
                                ))
                            }
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className='footerLeaguesLinksContainer'>
                            <h4 className='mb-4'><strong>Leagues</strong></h4>
                            {
                                leagues?.slice(0, 8).map((league) => (
                                    <p onClick={() => setModalShow(true)}>
                                        {league.leagueName}
                                    </p>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h4 style={{ textAlign: 'center', color: 'white' }}><strong>Follow Us</strong></h4>
                </Row>

            </Container>
            <div className="copyRight" >
                <p style={{ margin: 0, paddingTop: 0 }}>Copyright © 2023 - All right reserved.</p>
            </div>
            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            setShow={setModalShow}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                        />
                    :
                    <Modale
                        setShow={setModalShow}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />
            }
        </Container>
    )
}



