import React from 'react'
import './styles.css'
import Cookies from 'universal-cookie';
import { Nav } from 'react-bootstrap';
import { useState } from 'react';
import { navLinks } from '../navigation/navLinks';
import Modale from '../modal';
import Buttons from '../button';

const Auth = () => {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);


    return (
        <>
            {
                !cookie.get('token') &&

                <div className='sprotsLinkWrapper'>
                    <div className='sportsLinkTitle'>
                        <h5>Auth</h5>
                    </div>
                    <div className='sportsLinkContainer'>
                        {
                            !cookie.get('token') &&

                            <div className='actionLinksContainer'>
                                <div style={{ marginBottom: 8 }}>
                                    <Buttons text='SignIn' kind="primary" signin={true} />
                                </div>
                                <Buttons text='SignUp' kind="primary" signup={true} />
                            </div>

                        }
                    </div>

                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        setModalShow={setModalShow}
                        signin={cookie.get('token') ? false : true}
                        message={cookie.get('token') ? true : false}
                    />
                </div>
            }
        </>
    )
}

export default Auth