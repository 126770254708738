import React from 'react'
import './styles.css'
import Cookies from 'universal-cookie';
import { useState } from 'react';
import Modale from '../modal';
import { leagues } from './data';

const USALeagues = () => {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);


    return (
        <div className='usaLeagueWrapper'>
            <div className='usaLeagueTitle'>
                <h5>Leagues</h5>
            </div>
            <div className='usaLeaguesContainer'>
                {leagues?.map((data) =>
                    <div className='usaLeagueContainer' onClick={() => setModalShow(true)}>
                        <img src={data.icon} width={35} height={35} />
                        <p key={data.id} style={{ cursor: 'pointer' }} className='usaLeagueText'>{data.leagueName}</p>
                    </div>
                )}
            </div>

            {
                cookie.get('token') ?
                    cookie.get('messageStatus') === '0' ?
                        null
                        :
                        <Modale
                            setShow={setModalShow}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            message={true}
                        />
                    :
                    <Modale
                        setShow={setModalShow}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />
            }
        </div>
    )
}

export default USALeagues