import React, { useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import moment from 'moment/moment';
import { getTransactions } from '../../../../api';
import { Badge, Col, Container, Row, Table } from 'react-bootstrap';

function Transactions() {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [transactions, setTransactions] = useState([]);

    useEffect(
        () => {
            getTransactions(id).then(async res => {
                // const transactionResult = [];
                // res.data.forEach(element => {
                //     if (element.status === 0) {
                //         transactionResult.push(element)
                //     }
                // });

                setTransactions(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])



    return (
        <Col lg={12} md={12} sm={12} xs={12}>
            <div className='transactionsWrapper'>
                {/* <p className='transactionSentence'>Browse your transaction history for full financial visibility.</p> */}
                <div className='transactionsContainer'>
                    <Table responsive="sm">
                        <thead className='transactionTableHead'>
                            <tr>
                                <th><b>From</b></th>
                                <th><b>To</b></th>
                                <th><b>Amount</b></th>
                                <th><b>Currency</b></th>
                                {/* <th><b>Date</b></th> */}
                                <th><b>Status</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactions?.map(item => (
                                    <>
                                        <tr key={item._id}>
                                            <td align='center'><p>{cookie.get('username')}</p></td>

                                            <td align='center'><p>{item.receiver}</p></td>

                                            <td align='center'><p>{item.amount}</p></td>
                                            <td align='center'><p>{item.sender.currency}</p></td>
                                            {/* <td align='center'><p>{moment(item.createAt).format('L')}</p></td> */}

                                            <td align='center'>
                                                {
                                                    item.status === 0 ?
                                                        <>
                                                            <Badge bg="warning" text="dark">
                                                                Pending
                                                            </Badge>
                                                        </>
                                                        :
                                                        <>
                                                            <Badge bg="success">Success</Badge>
                                                        </>
                                                }
                                            </td>
                                        </tr>

                                    </>

                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </Col>
    )
}

export default Transactions