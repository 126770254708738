import React, { useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { register } from '../../../api'
import '../style.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ContactModal from '../../contactmodal';



export default function SignUp({ SupportModalShow, setSupportModalShow, Close }) {
    const [startDate, setStartDate] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState()


    const [phoneValue, setPhoneValue] = useState()


    const validationSchema = Yup.object({
        firstname: Yup
            .string('first name')
            .required('First name is required'),
        lastname: Yup
            .string('last name')
            .required('Last name is required'),
        username: Yup
            .string('User name')
            .required('Username is required'),
        password: Yup
            .string('password')
            .required('Password is required'),
    })
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            birthdate: startDate,

        },
        validationSchema: validationSchema,
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                phone: phoneValue,
                username: formik.values.username,
                password: formik.values.password,
                birthdate: startDate,

            }

            const func = register(data)

            func.then(async (response) => {
                if (response.success) {
                    setErrorMessage(response.data.message)
                } else {
                    setErrorMessage('User already token')
                }
            })

        }
    })
    return (
        <Container>
            {SupportModalShow ? null
                : errorMessage ?
                    <>
                        <div style={{ padding: 16, backgroundColor: '#FFECCC', marginBottom: 32, marginTop: 24, borderRadius: 0 }}>
                            <p style={{ color: errorMessage ? 'red' : 'green' }}>{errorMessage}</p>
                            <Button
                                onClick={() => setSupportModalShow(true)}
                                style={{
                                    borderRadius: '0px',
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    border: 'none'
                                }}>Contact Us</Button>
                        </div>
                    </>
                    : null
            }

            {
                SupportModalShow ?
                    <>
                        <ContactModal Close={Close} setSupportModalShow={setSupportModalShow} />
                    </>
                    :
                    <>
                        {/* <p className='canvasSentence'>Sign up for exclusive access and premium content.</p> */}
                        <Form onSubmit={formik.handleSubmit}>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100%' }} className='mb-0'>
                                    <Form.Label className='signupinputLabel'><h5 style={{ margin: 0 }}>First Name</h5></Form.Label>
                                    <Form.Control
                                        className='textInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstname}
                                        type="text"
                                        name="firstname"
                                        placeholder="first name"
                                    />
                                </div>
                                <div style={{ width: '100%', marginLeft: 8 }} className='mb-0'>
                                    <Form.Label className='signupinputLabel'><h5 style={{ margin: 0 }}>Last Name</h5></Form.Label>
                                    <Form.Control
                                        className='textInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastname}
                                        type="text"
                                        name="lastname"
                                        placeholder="last name"
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100%' }} className='mb-0'>
                                    <Form.Label className='signupinputLabel'><h5 style={{ margin: 0 }}>Email Adress</h5></Form.Label>
                                    <Form.Control
                                        className='textInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        type="email"
                                        name="email"
                                        placeholder="email"
                                    />
                                </div>
                                <div style={{ width: '50%', marginLeft: 8 }} className='mb-0'>
                                    <Form.Group className="mb-0" controlId="formBasicPhone" style={{ marginRight: 0 }}>
                                        <Form.Label className='signupinputLabel'><h5 style={{ margin: 0 }}>Phone Number</h5></Form.Label>
                                        <PhoneInput
                                            style={{ height: 40 }}
                                            className='phoneNumber'
                                            value={phoneValue}
                                            onChange={setPhoneValue}
                                            name="phone"
                                            placeholder="phone number"
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>

                                <div style={{ width: '100%' }} className='mb-0'>
                                    <Form.Label className='signupinputLabel'><h5 style={{ margin: 0 }}>Username</h5></Form.Label>
                                    <Form.Control
                                        className='textInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.username}
                                        type="text"
                                        placeholder="username"
                                        name="username" />
                                </div>
                                <div style={{ width: '100%', marginLeft: 8 }} className='mb-0'>
                                    <Form.Label className='signupinputLabel'><h5 style={{ margin: 0 }}>Password</h5></Form.Label>
                                    <Form.Control
                                        className='textInput'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        type="password"
                                        name="password"
                                        placeholder="password"
                                    />
                                </div>
                            </div>

                            <div className='mb-3'>
                                <Form.Group className="mb-3" controlId="formBasicBirthDate">
                                    <Form.Label className='signupinputLabel'><h5 style={{ margin: 0 }}>Date Of Birth</h5></Form.Label>
                                    <DatePicker
                                        className='date'
                                        selected={startDate}
                                        name='birthdate'
                                        onChange={(date) => setStartDate(date)}
                                        value={startDate}
                                    />
                                </Form.Group>
                            </div>

                            <Button
                                type="submit"
                                className='ModalFooterSubmit'
                            >
                                Sign Up
                            </Button>
                        </Form>
                    </>
            }

        </Container >
    )
}
